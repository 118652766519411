import { Observable } from 'rxjs';
export enum ApiEndPoints {
  signIn = 'auth/signIn',
  signUp = 'auth/signUp',
  signOut = 'auth/signOut',

  guestLogIn = 'auth/guest/login',
  checkAuth = 'auth/checkAuth',
  getToken = 'auth/getTokenData',
  setPassword = 'auth/setPassword',
  sendEmail = 'auth/sendEmail',
  sendOtp = 'auth/otp/sendOtp',
  verifyOtp = 'auth/otp/verifyOtp',
  newPassword = 'auth/otp/createPassword',

  profileRoleCheck = 'auth/profileRole',
  profileGet = 'profile/get',
  profileEdit = 'profile/update',
  profileFind = 'profile/find',
  profileVisible = 'profile/visible',
  profileGeneralUpdate = 'profile/generalUpdate',

  profileRecordCreate = 'profile/record/create',
  profileRecordUpdate = 'profile/record/update',
  profileRecordDelete = 'profile/record/delete',

  projectGet = 'project/find',
  projectView = 'project/get',
  projectCReate = 'project/create',
  projectUpdate = 'project/update',
  userProjectList = 'project/getUserProjects',
  projectVideoUpload = 'project/upload',

  organisationGet = 'organization/find',
  organisationView = 'organization/get',
  organisationCreate = 'organization/create',
  organisationUpdate = 'organization/update',

  serviceConfigGet = 'serviceConfig/find',

  waitingList = 'auth/get/waitingList',
  milestoneConfigCreate = 'milestone/config/create',
  milestoneConfigFind = 'milestone/config/find',
  milestoneConfigGet = 'milestone/config/get',

  milestoneGet = 'milestone/get',
  milestoneFind = 'milestone/find',
  milestoneUpdate = 'milestone/update',
  milestoneDelete = 'milestone/delete',

  milestoneCustomCreate = 'milestone/customCreate',
  milestoneCustomGet = 'milestone/getConfig',

  emailSignUP = 'auth/email/signUp',
  emailSignIn = 'auth/email/signIn',

  templateCreate = 'template/create',
  templateFind = 'template/find',
  templateUpdate = 'template/update',
  templateEmail = 'template/send',

  notificationTemplateCreate='masterConfig/notification/template/create',
  notificationTemplateFind='masterConfig/notification/template/find',
  notificationTemplateUpdate='masterConfig/notification/template/update',

  search = 'general/search',
  customSearch = 'general/custom/search',
  locationSearch = 'general/location',
  projectSearch = 'general/custom/project/search',

  adminCreate = 'admin/create/theaterOrdistributor',

  algoAssetCreate = 'algo/create/asset',
  algoTransferAsset = 'algo/asa/transfer',
  algoTxnInfo = 'algo/asa/transfer/info',

  algoPayment = 'algo/payment',
  algoPaymentConfirm = 'algo/payment/confirm',

  algoFindUser = 'algo/find/useraccount',
  algoFindAsset = 'algo/find/asset',

  memberInvite = 'enrollment/invite',
  acceptInvite = 'enrollment/accept',
  declineInvite = 'enrollment/cancel',

  algoTransfer = 'algo/transfer/asset',
  algoAccount = 'algo/account',
  algoAsset = 'algo/asset',

  transactionFind ='algo/transaction/find',
  notificationFind = 'notification/find',

  masterCreate = 'masterConfig/create',
  masterFind ='masterConfig/find',
  googleMetaData = 'masterConfig/metaData/findOne',

  watchListCreate = 'watchList/create',
  watchListFind = 'watchList/find',
  watchListGet = 'watchList/get',
  watchListUpdate = 'watchList/update',

  eventCreate = 'event/create',
  eventFind = 'event/find',
  eventGet = 'event/get',
  eventUpdate = 'event/update',

  eventSubmisionCreate = 'event/submission/create',
  eventSubmisionFind = 'event/submission/find',
  eventSubmisionGet = 'event/submission/get',
  eventSubmisionUpdate = 'event/submission/update',

  levelCreate = 'event/level/create',
  levelGet = 'event/level/get',

  membershipCreate ='membership/create',
  membershipGigsCreate ='membership/gigs/create',

  gigsCreate = 'gigs/create',
  gigsFind = 'gigs/find',
  gigsGet = 'gigs/get',
  gigsUpdate = 'gigs/update',

  generalDelete = 'admin/generalDelete',
}

export const sidebarList = [
  {
    title: 'Manage Users',
    icon: '/assets/images/svg/home.svg',
    url: '/main/user/management',
  },
  {
    title: 'Manage Config',
    icon: '/assets/images/svg/event.svg',
    url: '/main/user/manage-confiq',
  },

  // {
  //   title: 'Person',
  //   icon: '/assets/images/svg/person.svg',
  //   url: '/main/admin-landing',
  // },
];
