import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/guard/auth.service';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {


 passwordForm: FormGroup;

  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private apiHelper: ApiHelper,
    private router: Router,
    private authService: AuthService
  ) {
    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  get passwordMismatch(): boolean {
    return (
      this.passwordForm.get('newPassword')?.value !==
      this.passwordForm.get('confirmPassword')?.value
    );
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSubmit() {
    if (!this.passwordMismatch) {
      const newPassword = this.passwordForm.get('newPassword')?.value;
      this.activatedRoute.params.subscribe((params) => {
        let uniqueToken = params['token'];
        const data = {
          uniqueToken: uniqueToken,
          password: newPassword,
        };

        this.apiHelper
          .post(data, ApiEndPoints.setPassword, false)
          .subscribe((response) => {
            var session = response.data.session;
            var profile = response.data.profile;

            let sessionString = JSON.stringify(session);
            localStorage.setItem('session', sessionString);
            localStorage.setItem('profile', JSON.stringify(profile));

            this.authService.running = true;
            var loginVal = 'logged';
            localStorage.setItem('currentUser', JSON.stringify({ loginVal }));

            if (this.authService.running) {
              this.router.navigate(['/view']);
            }
          });
      });
    }
  }

}
