import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  sortArrayAlphabetically(arr: string[]): string[] {
    const uniqueWords = new Set<string>();
    return arr
      .filter((word) => {
        const normalizedWord = word.toLowerCase();
        if (uniqueWords.has(normalizedWord)) {
          return false; // Exclude duplicates
        }
        uniqueWords.add(normalizedWord);
        return true;
      })
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  sortJsonArray<T>(arr: T[], key: keyof T): T[] {
    if (!Array.isArray(arr) || arr.length === 0) {
      console.warn("Expected a non-empty array but received:", arr);
      return [];
    }
  
    return arr.sort((a, b) => {
      const valueA = String(a[key]).toLowerCase();
      const valueB = String(b[key]).toLowerCase();
      return valueA.localeCompare(valueB);
    });
  }
  
}
