import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'social-media-share',
  template: `
    <div class="social-media-share bg-app-primary text-white">
  <p class="share-title">{{ data.title | trimText : 40 }}</p>
  <p class="share-description">{{ data.description | trimText: 50 }}</p>

  <div class="platforms">
    <div *ngFor="let platform of platforms" class="platform">
      <a [href]="getShareLink(platform)" target="_blank">
        <img [src]="getIconUrl(platform)" [alt]="platform" />
      </a>
      <span class="platform-label">{{ platform | titlecase }}</span>
    </div>
  </div>

  <div class="share-link">
    <input type="text" [value]="data.url" readonly />
    <button (click)="copyLink()" class="btn btn-grad-ff text-white">Copy</button>
  </div>
</div>

  `,
  styles: [`
    .social-media-share {
      padding: 20px;
      text-align: center;
      width: 400px;
      margin: 0 auto;
    }

    .share-header {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .platforms {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
    }

    .platform {
      text-align: center;
    }

    .platform img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: transform 0.3s;
      background-color: #fff;
      padding: 5px;
    }

    .platform img:hover {
      transform: scale(1.1);
    }

    .platform-label {
      display: block;
      margin-top: 5px;
      font-size: 0.8rem;
      color: #aaa;
    }

    .share-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      background-color: #1c1c1c;
      padding: 10px;
      border-radius: 5px;
    }

    .share-link input {
      flex: 1;
      background: none;
      border: none;
      color: white;
      font-size: 0.9rem;
      padding: 5px;
    }

    .share-link input:focus {
      outline: none;
    }

    .share-link button {
      background-color: #3b82f6;
      padding: 5px 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .share-link button:hover {
      background-color: #2563eb;
    }

    .share-caption {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
  color: #ddd;
}
  `]
})
export class SocialMediaSharingComponent {
  platforms: string[] = ['whatsapp', 'facebook', 'x', 'linkedin'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: string; caption: string; title: string; description: string },
    private toastrService: ToastrService
  ) {}

  getIconUrl(platform: string): string {
    return `assets/images/social-media-img/${platform}.svg`;
  }

  getShareLink(platform: string): string {
    const encodedUrl = encodeURIComponent(this.data.url);
    const encodedCaption = encodeURIComponent(this.data.caption);
    const encodedTitle = encodeURIComponent(this.data.title);
    const encodedDescription = encodeURIComponent(this.data.description);
  
    switch (platform) {
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedCaption}`;
      case 'x':
        return `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedCaption} ${encodedTitle} - ${encodedDescription}`;
      case 'whatsapp':
        return `https://api.whatsapp.com/send?text=${encodedCaption}%20${encodedTitle}%20-%20${encodedDescription}%20${encodedUrl}`;
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
      default:
        return '#';
    }
  }
  
  

  copyLink(): void {
    navigator.clipboard.writeText(this.data.url).then(() => {
      this.toastrService.success('Link copied to clipboard!');
    });
  }
}
