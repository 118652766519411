<div class="smallHeader">
  <div class="container-img d-flex align-items-center justify-content-center">
    <div class="container-lg">
      <div class="row">
        <div class="col-md-6 col-lg-5">
          <h1 class="title1-highlighted">
            Spotlight Your <span class="title1-gradient">Story!</span>
          </h1>
          <h4 class="title4-dim">
            Create your profile with your personal journey, projects,
            achievements, and awards. Let the world see your work, connect
            with industry professionals, and unlock exclusive features that
            celebrate your unique cinematic path.
          </h4>
          <button
          class="btn btn-grad-ff btn-profile-view"
          [routerLink]="['profile/view', userProfile]"
        >
          Create Your Profile Now
        </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="body-bg">
  <div class="mb-4">
    <div class="pb-4">
      <div class="search-filter-wrap">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="filter-item">
            <input class="form-control form-control-lg" type="search"
              (input)="searchFilter('freeText' , $event)" placeholder="Search" aria-label="Search" />
            <!-- <app-search class="w-100 pb-2"  (searchResultList)="profileSearchResultData($event)"></app-search> -->
            <!-- <div class="bg-primary-400">
              <div class="col-md-5 search-container bg-primary-400">
                <input class="input-field form-control search mr-sm-2" type="search"
                  (input)="searchFilter('freeText' , $event)" placeholder="Search" aria-label="Search" />
              </div>
            </div> -->
          </div>
          <div class="filter-item">
            <select class="form-control form-select-lg" #locationsSelect
              (change)="searchFilter('location', $event)">
              <option value="" style="display: none">location</option>
              <option *ngFor="let location of locations" [value]="location">
                {{ location }}
              </option>
            </select>
          </div>
    
          <div class="filter-item">
            <select class="form-select form-select-lg" #rolesSelect
              (change)="searchFilter('role', $event)">
              <option value="" style="display: none">role</option>
              <option class="dropdown-value" *ngFor="let role of roleLists" [value]="role">
                {{ role }}
              </option>
            </select>
          </div>
          <div class="filter-item">
            <button class="btn btn-grad-ff" (click)="onCancel(locationsSelect,rolesSelect)">
              Clear
            </button>
          </div>
        </div>
    
      </div>
    </div>
  </div>
  <div class="container-lg">
    <div class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-6 col-xl-5col"
        *ngFor="let item of profiles"
        [routerLink]="['profile/view', item.id]"
      >
        <!-- <div *ngIf="isValid(item)"> -->
        <div class="card-wrap card-wrap--hover rounded pb-3">
          <div class="profile-cardPic mb-4">
            <img
              [src]="item.pictureFileLocations | imagesource:'profile'"
         
              alt="Card image cap"
            />
          </div>
          <div class="ms-4">
            <h5 class="title5-highlighted">{{ item.name }}</h5>
            <h6 class="title6-highlighted">
              <img
                src="assets/images/svg/theaters.svg"
                class="me-2"
              />{{ dataFormator.multiple(item?.industryRole) }}
            </h6>
            <h6 class="title6-highlighted">
              <img
                src="assets/images/svg/location_on.svg"
                class="me-2"
              />{{
                dataFormator.locationArray(
                  item?.location?.city,
                  item?.location?.country
                )
              }}
            </h6>
          </div>
          <!-- </div> -->
          <!-- <a
          class="btn btn-grad-ff text-white mt-2"
          [routerLink]="['profile/view', item.id]"
          >View More</a
          > -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="pagination">
    <button
      class="pagination-button"
      *ngIf="pagenationData.offset != 1"
      (click)="pagenation('previous')"
    >
      &laquo; Prev
    </button>
    <button
      class="pagination-button"
      *ngIf="profileData"
      (click)="pagenation('next')"
    >
      Next &raquo;
    </button>
  </div>
</div>
