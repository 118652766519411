import { Component, OnInit } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ConfigCheckService } from 'src/app/core/service/configCheck.service';
import { dataformatorService } from 'src/app/core/service/dataformator.service';
import { SortingService } from 'src/app/core/service/sorting.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  countriesAndCities: string[] = [];

  roleLists: string[] = [];

  locations: any;

  combinedSet = new Set<string>();

  profiles: any;

  profileData: boolean = true;

  newState!: boolean;

  userProfile: any;

  searchQuery: any = {
    freeText: '',
    role: '',
    location: '',
  };

  pagenationData: any = {
    limit: 30,
    offset: 1,
  };

  constructor(
    private apiHelper: ApiHelper,
    private sortService: SortingService,
    public dataFormator: dataformatorService,
    public dataCheckConfig: ConfigCheckService
  ) {

this.apiHelper
    .post({ type: "roles"}, ApiEndPoints.masterFind)
    .subscribe((response) => { 
      if (!this.isEmpty(response.data)) {
        this.roleLists = this.sortService.sortArrayAlphabetically(response.data[0].data);
      }
    });
  }

  ngOnInit(): void {
    let userSessionString: any = localStorage.getItem('session');
    let session = JSON.parse(userSessionString);
    this.userProfile = session.profileId;
    this.getSearchResults(this.searchQuery, this.pagenationData);
    this.getLocationLIst();
  }

  isEmpty(data: any): boolean {
    if (!data || !Array.isArray(data)) {
      return true;
    }
    return data.length === 0;
  }

  pagenation(type: string) {
    if (type === 'next') this.pagenationData.offset++;
    if (type === 'previous' && this.pagenationData.offset > 1) {
      this.pagenationData.offset--;
    }
    this.getSearchResults(this.searchQuery, this.pagenationData);
  }

  getSearchResults(criteria: any, pagenation: any = this.pagenationData) {
    this.apiHelper
      .post({ filters: criteria, pagenation }, ApiEndPoints.customSearch)
      .subscribe((response) => {
        this.profiles = response.data.profileDetails;
        this.profileData = response.data.hasNextPage;
      });
  }

  searchFilter(type: string, event: any) {
    this.newState = false;
    let data = event.target.value;
    this.searchQuery[type] = data;
    this.pagenationData.offset = 1
    this.getSearchResults(this.searchQuery);
  }

  onCancel(locationSelect: any, rolesSelect: any) {
    this.newState = false;
    locationSelect.value = '';
    rolesSelect.value = '';
    this.searchQuery = {
      freeText: '',
      role: '',
      location: '',
    };
    this.getSearchResults(this.searchQuery);
  }

  getLocationLIst() {
    this.apiHelper
      .post({}, ApiEndPoints.locationSearch)
      .subscribe((response) => {
        response.data.forEach((item: any) => {
          if (item && item.location) {
            if (item.location.country && item.location.country.trim() !== '') {
              this.combinedSet.add(item.location.country.toLowerCase());
            }

            if (item.location.city && item.location.city.trim() !== '') {
              this.combinedSet.add(item.location.city.toLowerCase());
            }
          }
        });
        this.countriesAndCities = Array.from(this.combinedSet);
        this.locations = this.sortService.sortArrayAlphabetically(
          this.countriesAndCities
        );
      });
  }
}
