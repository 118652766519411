<div class="jumbotron-custom vendors">
  <div class="container-lg">
      <h1 class="title1-highlighted">Finding the Right Partners for Your <span class="title1-gradient">Film's
              Success</span></h1>
      <h4 class="title4-dim">Showcase your services, connect with filmmakers, and establish yourself as a trusted partner in the filmmaking process. Expand your reach and grow your business within the film industry.</h4>
      <div class="mt-4">
          <button class="btn btn-grad-ff mb-2 mx-2 btn-lg" [routerLink]="['/view/vendor/organisation/create']">Add organaization</button>
      </div>
  </div>
</div>
<!-- 
<div class="search-filter-wrap ">
  <div class="d-flex justify-content-center flex-wrap">
      <div class="filter-item">
          <input type="search" placeholder="Search" aria-label="Search" class="form-control form-control-lg">
      </div>
      <div class="filter-item">
          <select class="form-select form-select-lg">
              <option value="">Film</option>
              <option value="">Role 2</option>
              <option value="">Role 3</option>
              <option value="">Role 4</option>
          </select>
      </div>
      <div class="filter-item">
          <select class="form-select form-select-lg">
              <option value="">Service</option>
              <option value="">Service 2</option>
              <option value="">Service 3</option>
              <option value="">Service 4</option>
          </select>
      </div>
      <div class="filter-item">
          <button class="btn btn-grad-ff">Clear</button>
      </div>
  </div>
</div> -->

<div class="container-lg mt-5">
  <div class="row">
    <div
      class="col-lg-3 col-md-6 col-sm-12 col-xl-5col mb-3"
      *ngFor="let item of organisations"
      [routerLink]="['details', item.id]"
    >
      <div class="card-wrap card-wrap--hover rounded pb-3 min-h-xl">
        <div class="profile-cardPic position-relative">
          <img
            class="rounded-top img-fluid"
            [src]="item.pictureFileLocations | imagesource:'profile' "
           
            alt="project Image"
          />
          <!-- <a href="#" class="visit-link"><img src="assets/images/img/globe.png" width="20" height="20" class="me-2" alt="">Visit Site</a> -->
        </div>
        <div class="mx-4">
          <h5 class="title5-highlighted custom-tooltip-ff text-capitalize w-100"
          [attr.data-tooltip]="item.name.length > 15 ? formatTitle(item.name, 15) : null">
            {{ item.name | trimText: 15 }}
          </h5>
          <h6 class="title6-highlighted custom-tooltip-ff w-100"
          [attr.data-tooltip]="item.vendorName.length > 15 ? formatTitle(item.vendorName, 15) : null">      
          <img src="../../../../assets/images/img/business_center.png" class="me-2" alt="">
            {{ item.vendorName | trimText:15 }}
          </h6>
          <h6 class="title6-highlighted custom-tooltip-ff w-100" [attr.data-tooltip]="item.location.length > 15 ? formatTitle(item.location, 15) : null">
            <img src="../../../../assets/images/img/location_on.png" class="me-2 pe-1" alt="">{{ item.location | trimText:15 }}</h6>
          <h6 class="title6-highlighted custom-tooltip-ff w-100"
          [attr.data-tooltip]="item.email.length > 15 ? formatTitle(item.email, 15) : null">
            <img src="../../../../assets/images/img/alternate_email.png" class="me-2" alt="">
            {{ item.email | trimText: 15 }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
