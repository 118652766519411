import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SessionExpiredComponent } from 'src/app/shared/components/session-expired/session-expired.component';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private zone: NgZone,
    private dialog: MatDialog
  ) {}

  handleError(error: unknown): void {
    console.error('Handler caught an error', error);
    
    if(this.isSessionExpiryError(error)){
      const dialogRef = this.dialog.open(SessionExpiredComponent, {
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe(() => {
        this.redirectToLogin();
      });
    return;
    }
    if (
      this.isUnauthorizedError(error) ||
      this.isNullPropertyError(error) ||
      this.isDataNotFoundError(error)
      
    ) {
        this.redirectToLogin();
    }


    this.snackBar.open('Error detected! We are working on it!', 'Close', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  private isUnauthorizedError(error: unknown): boolean {
    return error instanceof HttpErrorResponse && error.status === 403;
  }
  private isDataNotFoundError(error: unknown): boolean {
    return error instanceof HttpErrorResponse && error.status === 410;
  }
  private isSessionExpiryError(error: unknown): boolean {
    return error instanceof HttpErrorResponse && error.status === 424;
  }

  private isNullPropertyError(error: unknown): boolean {
    return (
      error instanceof TypeError &&
      error.message.includes('Cannot read properties of null')
    );
  }
  // private isundefinedError(error: unknown): boolean {
  //   return error instanceof TypeError && error.message.includes('Cannot read properties of undefined');
  // }

  private redirectToLogin(): void {
    this.zone.run(() => {
      localStorage.removeItem('session');
      localStorage.removeItem('profile');
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    });
  }
}

// private errorLog: Map<string, number> = new Map();
// private readonly MAX_SAME_ERROR_COUNT = 3;
// private readonly ERROR_RESET_TIME = 5000;
// constructor(
//   private snackBar: MatSnackBar,
//   private router: Router,
//   private zone: NgZone
// ) {}

// handleError(error: unknown): void {
//   const errorKey = this.getErrorKey(error);
//   const currentCount = this.errorLog.get(errorKey) || 0;

//   if (currentCount >= this.MAX_SAME_ERROR_COUNT) {
//     // Stop logging repetitive errors
//     console.error('Error suppressed due to repeated occurrence', error);
//     return;
//   }
//   this.errorLog.set(errorKey, currentCount + 1);

//   if (error instanceof HttpErrorResponse && error.status === 401) {
//     this.router.navigate(['/login']);
//   }
//   if (
//     error instanceof TypeError &&
//     error.message.includes('Cannot read properties of null')
//   ) {
//     this.zone.run(() => {
//       this.router.navigate(['/login']); // Ensure navigation happens inside Angular's zone
//     });
//   }
//   // Show error snackbar
//   this.snackBar.open('Error was detected! We are working on it!', 'Close', {
//     duration: 1000,
//     horizontalPosition: 'center',
//     verticalPosition: 'top',
//   });

//   // Log the warning
//   console.error('Handler caught an error', error);

//   // Reset error count after a timeout
//   setTimeout(() => {
//     this.errorLog.delete(errorKey);
//   }, this.ERROR_RESET_TIME);
// }

// private getErrorKey(error: unknown): string {
//   // Create a unique identifier for the error
//   return error instanceof Error
//     ? `${error.name}:${error.message}`
//     : JSON.stringify(error);
// }

// private getCustomErrorMessage(error: any) {
// Define custom error messages based on error type or code
// if (error instanceof HttpErrorResponse) {
//   return error.error;
// }
// switch (error.status) {
//   case 401:
//     return 'Unauthorized access. Please log in.';
//   case 404:
//     return 'Resource not found.';
//   case 500:
//     return 'Internal server error. Please try again later.';
//   default:
//     return 'An unexpected error occurred.';
// }
// return 'An error occurred: ' + error.message;
//   switch (error.code) {
//     case 'NG0200':
//       return 'Circular dependency in DI detected.';
//     case 'NG0201':
//       return 'No provider for the specified token.';
//     case 'NG0300':
//       return 'Template parse errors.';
//     case 'NG0303':
//       return 'Expression has changed after it was checked.';
//     case 'NG0304':
//       return 'Cannot read property of undefined in the template.';
//     case 'NG0400':
//       return 'FormControlName must be used with a parent FormGroup.';
//     case 'NG0401':
//       return 'No value accessor for the form control.';
//     case 'NG0500':
//       return 'Cannot match any routes for the provided URL segment.';
//     case 'NG0501':
//       return 'Router outlet is not activated.';
//     case 'NG0600':
//       return 'Injector has already been destroyed.';
//     case 'NG0601':
//       return 'Cannot assign to a read-only property.';
//     default:
//       return 'An unexpected error occurred.';
//   }
// }
// }
