<footer class="foot" >
    <div class="d-flex justify-content-between flex-wrap">
      <div class="logo-sm img">
        <img
          src="assets/images/img/filmfinance.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>

      <div class="copyright-links">
        <div class="d-flex ">
          <ul class="footer-link ">
            <li>
              <a  href="https://filmfinance.app/privacy.html" target="_blank" rel="noreferrer noopener" class="text-white">Privacy Policy</a>
            </li>
            <li>
              <a href="https://filmfinance.app/terms.html" target="_blank" rel="noreferrer noopener"  class="text-white">Terms & Conditions </a>
            </li>
            <li>
              <a href="https://filmfinance.app/contact.html" target="_blank" rel="noreferrer noopener"  class="text-white">Contact Us</a>
            </li>
          </ul>
        </div>
        <p class="copyright text-white-50">
          Copyright &#64;2023 Film Finance App. All rights reserved.
        </p>
      </div>
      <div class="social-m-icons float-end">
        <a href="https://x.com/Filmfinanceapp" target="_blank"
          ><img src="assets/images/img/twitter.webp " alt="Twitter"
        /></a>
        <a
          href="https://www.linkedin.com/company/filmfinanceapp/"
          target="_blank"
          ><img src="assets/images/img/linkedin.webp" alt="LinkedIn"
        /></a>
        <a href="https://www.instagram.com/filmfinance.app/" target="_blank"
          ><img src="assets/images/img/insta.webp " alt="Instagram"
        /></a>
      </div>
    </div>
    <div class="copyright-links sm-view">
      <div class="d-flex justify-content-center">
        <ul class="footer-link">
          <li>

            <a href="https://filmfinance.app/privacy.html" target="_blank" rel="noreferrer noopener"   class="text-white">Privacy Policy</a>
          </li>
          <li>
         <a href="https://filmfinance.app/the-journey.html" target="_blank" rel="noreferrer noopener"   class="text-white">Terms & Conditions </a>
          </li>
          <li>
            <a href="https://filmfinance.app/contact.html" class="text-white">Contact Us</a>
          </li>
        </ul>
      </div>
      <p class="copyright text-white-50">
        Copyright &#64;2023 Film Finance App. All rights reserved.
      </p>
    </div>
</footer>
