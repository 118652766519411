import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileuploadService {
  constructor(private http: HttpClient) {}

  appendFormData(data: any, parentKey: string = ''): FormData {
    const formData = new FormData();


  function appendData(data: any, parentKey: string = ''): void {
    if (data !== null && data !== undefined) {
      if (Array.isArray(data)) {
        data.forEach((item, index) => {
          const newKey = `${parentKey}[${index}]`; // Format as parentKey[index]
          appendData(item, newKey);
        });
      } else if (typeof data === 'object' && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          const value = data[key];
          const newKey = parentKey ? `${parentKey}[${key}]` : key;
          appendData(value, newKey);
        });
      } else {
        formData.append(parentKey, data);
      }
    }
  }

  appendData(data, parentKey);
  return formData;
}


  //   function appendData(data: any, parentKey: string = ''): void {
  //     if (data) {
  //       if (
  //         !Array.isArray(data) &&
  //         typeof data === 'object' &&
  //         !(data instanceof File)
  //       ) {
  //         Object.keys(data).forEach((key) => {
  //           const value = data[key];
  //           const newKey = parentKey ? `${parentKey}[${key}]` : key;
  //           appendData(value, newKey);
  //         });
  //       } else {
  //         formData.append(parentKey, data);
  //       }
  //     }
  //   }


  //   appendData(data, parentKey);
  //   return formData;
  // }
}
