import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { FileuploadService } from 'src/app/core/service/fileupload.service';
import { SortingService } from 'src/app/core/service/sorting.service';
import { TaskService } from 'src/app/core/service/task.service';
import {
  defaultRules,
  validateFields,
} from 'src/app/shared/components/field-validation/validation-service';
import { ImageCropComponent } from 'src/app/shared/components/image-crop/image-crop.component';

@Component({
  selector: 'app-profile-onboading-page',
  templateUrl: './profile-onboading-page.component.html',
  styleUrls: ['./profile-onboading-page.component.scss'],
})
export class ProfileOnboadingPageComponent {
  defaultRules = defaultRules;
  startAt = new Date(new Date().setFullYear(new Date().getFullYear() - 12));
  croppedImage: any;
  spinner: any;
  preViewImage: string[] = [];
  onboardingForm: FormGroup;
  userDetails: any;
  profileDetails: any;
  userImage: any;
  userPreviewImage: string[] = [];
  roleLists: string[] = [];
  locationDisplay: string = '';
  locationSuggestions: any[] = [];
  debounceTimer: any;
  session: any;
  profileId: any;
  config: any = {
    industryRole: {
      selectArray: [],
      formControlNames: 'industryRole',
    },
  };
  carousel: any;
  hi: any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialog,
    private apiHelper: ApiHelper,
    private sorting: SortingService,
    private router: Router,
    private dialog: MatDialog,
    private taskService: TaskService,
    private toastrService: ToastrService,
    private fileUploadService: FileuploadService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.onboardingForm = this.fb.group({
      industryRole: [, Validators.required],
      configType: ['profile'],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      location: this.fb.group({
        city: [],
        country: [],
      }),
      phoneNumber: [''],
    });

    this.apiHelper
      .post({ type: 'roles' }, ApiEndPoints.masterFind)
      .subscribe((response) => {
        if (!this.isEmpty(response.data)) {
          this.roleLists = this.sorting.sortArrayAlphabetically(
            response.data[0].data
          );
        }
      });
    let userSessionString: any = localStorage.getItem('session');
    this.session = JSON.parse(userSessionString);
    this.profileId = this.session.profileId;
  }

  isEmpty(data: any): boolean {
    if (!data || !Array.isArray(data)) {
      return true;
    }
    return data.length === 0;
  }

  ngOnInit() {
    const id = this.data.userData.session.profileId;
    this.apiHelper
      .post({ id: id }, ApiEndPoints.profileGet)
      .subscribe((response) => {
        this.userDetails = response.data;
        this.userPreviewImage[0] = this.userDetails.pictureFileLocations;
        this.onboardingForm.patchValue(this.userDetails);
        if (
          this.userDetails?.location?.city &&
          this.userDetails?.location?.country
        ) {
          this.locationDisplay = `${this.userDetails.location.city}, ${this.userDetails.location.country}`;
        }
        this.config['industryRole'].selectArray =
          this.userDetails.industryRole || [];
      });
    if (this.data.userData.loginType === 'google') {
      this.onboardingForm
        .get('phoneNumber')
        ?.setValidators([Validators.required]);
    }
  }

  getErrorMessage(
    formGroup: FormGroup,
    controlName: string,
    errorKey: string
  ): string | null {
    const control = formGroup.get(controlName);
    if (control?.hasError(errorKey)) {
      return control.errors?.[errorKey] || null;
    }
    return null;
  }

  onSubmit(): void {
    if (this.onboardingForm.valid) {
      this.onboardingForm
        .get('dob')
        ?.setValue(this.handleInput(this.onboardingForm.value.dob));
      let formData = this.fileUploadService.appendFormData(
        this.onboardingForm.value
      );
      if (this.userImage) {
        formData.append('file', this.userImage);
      }
      formData.append('id', this.profileId);
      formData.append('loginType', this.data.userData.loginType);
      this.apiHelper
        .post(formData, ApiEndPoints.profileGeneralUpdate)
        .subscribe(
          (response) => {
            this.profileDetails = response.data;
            this.taskService.updateUserDetails(response.data);
            this.dialogRef.closeAll();
            this.router.navigate(['/view']);
          },
          (error) => {
            this.toastrService.error(error.error.message);
          }
        );
    }
  }

  onChange(event: any) {
    let input = event.target.files[0];
    let dialogRef = this.dialog.open(ImageCropComponent, {
      data: { file: input },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.userImage = result;
      const file = this.userImage;
      if (file) {
        const objectURL = URL.createObjectURL(file);
        this.userPreviewImage[0] = objectURL;
      }
    });
  }
  removeImage() {
    this.userImage = '';
    this.userPreviewImage = [];
  }

  handleInput(selectedDate: any) {
    return new Date(selectedDate)?.toISOString();
  }

  handleSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value.trim();

    if (query.length < 2) {
      this.locationSuggestions = [];
      return;
    }

    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.fetchNominatimSuggestions(query);
    }, 250);
  }

  fetchNominatimSuggestions(query: string): void {
    fetch(
      `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=5&q=${encodeURIComponent(
        query
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.locationSuggestions = data.map((item: any) => ({
          display_name: item.display_name,
          city: item.address.county,
          country: item.address.country,
        }));
      })
      .catch((error) => console.error('Error fetching Nominatim data:', error));
  }

  selectSuggestion(suggestion: any): void {
    this.locationDisplay = `${suggestion.city}, ${suggestion.country}`;
    this.onboardingForm.patchValue({
      location: {
        city: suggestion.city,
        country: suggestion.country,
      },
    });
    this.locationSuggestions = [];
  }

  onItemSelect(event: any, type: string) {
    const configs = this.config[type];
    const selectedItem = event.target.value;
    if (selectedItem && !configs['selectArray'].includes(selectedItem)) {
      configs['selectArray'].push(selectedItem);
      this.onboardingForm
        .get(configs['formControlNames'])
        ?.setValue(configs['selectArray']);
    }
    event.target.value = '';
  }

  removeItem(item: string, type: string) {
    const configs = this.config[type];
    configs['selectArray'] = configs['selectArray'].filter(
      (r: any) => r !== item
    );
    this.onboardingForm
      .get(configs['formControlNames'])
      ?.setValue(configs['selectArray']);
  }
  isItemSelected(item: string, type: string): boolean {
    const configs = this.config[type];
    return configs['selectArray'].includes(item);
  }
  handleKeydown(event: KeyboardEvent, key: string) {
    if (event.key.length === 1) {
      event.preventDefault();
    }
  }
}
