import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { TaskService } from 'src/app/core/service/task.service';
import { NotificationComponent } from 'src/app/modules/notifcation/notification/notification.component';
import { NotificationViewComponent } from 'src/app/modules/notifcation/notification-view/notification-view.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  constructor(
    private router: Router,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
    private taskService: TaskService,
    private dialog: MatDialog
  ) {}

  profile!: any;
  userSession!: any;

  ngOnInit(): void {
    let sessionInLocalStorage: any = localStorage.getItem('session');
    let session = JSON.parse(sessionInLocalStorage);
    this.getProfileRecord(session.profileId);
    this.toggleMenu();
  }

  getProfileRecord(id: string) {
    this.apiHelper.post({ id: id }, ApiEndPoints.profileGet).subscribe(
      (response) => {
        if (response.data === null) {
          this.signOut();
          return;
        }
        this.taskService.updateUserDetails(response.data);
        this.taskService.currentUserDetails.subscribe(
          (details) => (this.userSession = details)
        );
      }
    );
  }

  signOut() {
    this.apiHelper.post({},ApiEndPoints.signOut).subscribe(()=> {
      localStorage.removeItem('session');
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    })
  }

  viewProfile(id: string) {
    this.router.navigate(['view/profile/view', id]);
  }
  viewInvestments() {
    this.router.navigate(['view/transactions']);
  }
  viewProjects() {
    this.router.navigate(['view/userProject']);
  }

  notificationPop() {
    // this.dialog.open(NotificationViewComponent)
    this.router.navigate(['view/notifications']);
  }
  toggleMenu() {
    document.querySelector('.menu-ico')?.addEventListener('click', function () {
      const navbarNav = document.querySelector('.navbar-nav');
      navbarNav?.classList.toggle('show');
    });
    let profile = document.querySelector('nav div.dropdown div.profile-pic-sm');
    let profileMenu = document.querySelector(
      'nav div.dropdown ul.dropdown-menu'
    );

    profile?.addEventListener('click', function () {
      profileMenu?.classList.toggle('d-block');
    });
    // Close the dropdown menu when clicking outside
    document.addEventListener('click', function (event: any) {
      // Check if the click happened outside the profile and the menu
      if (
        event.target &&
        !profile?.contains(event.target) &&
        !profileMenu?.contains(event.target)
      ) {
        profileMenu?.classList.remove('d-block'); // Remove the class to hide the menu
      }
    });
  }
}
