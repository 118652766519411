<div class="container-lg">
  <h2 class="title3-highlighted mt-3 mb-1">Tell us more about you</h2>
  <h3 class="title6-dim">Some basic details to get started</h3>
  <mat-dialog-content class="scrollbar">
  <form [formGroup]="onboardingForm">

    <div class="row mb-2">
      <div class="col-md-4 mb-2">
        <div class="profile-pic me-3">
          <img [src]=" 
                        userPreviewImage[0] ||
                        'assets/images/img/profile-avatar-1.png'" class="cursor-hand" alt="IMAGE" />

          <input type="file" id="BtnBrowseHidden" name="files" class="d-none" (change)="onChange($event)" />
          <div class="col text-end cursor-hand">
            <span class="edit-image profile-pic-action" *ngIf="userPreviewImage">
              <button class="btn btn-sm w-auto" mat-button [matMenuTriggerFor]="menu"><i
                  class="fa fa-edit"></i></button>
              <mat-menu #menu="matMenu" class="input-field form-control bg-app-primary">
                <button mat-menu-item>
                  <label for="BtnBrowseHidden" id="LblBrowse" class="text-white cursor-hand">Change Image
                  </label>
                </button>
                <button mat-menu-item class="text-white" (click)="removeImage()">
                  Remove Image
                </button>
              </mat-menu>
            </span>
            <span class="edit-image profile-pic-action" *ngIf="userPreviewImage[0] === null">
              <button class="btn btn-sm w-auto" mat-button [matMenuTriggerFor]="menu"><i
                  class="fa fa-edit"></i></button>
              <mat-menu #menu="matMenu" class="input-field form-control bg-app-primary">
                <button mat-menu-item>
                  <label for="BtnBrowseHidden" id="Lbl1Browse" class="text-white cursor-hand">Add image
                  </label>
                </button>
              </mat-menu>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-8 mb-2">
        <div class="mb-2">
          <h6 class="title6-highlighted">Role:</h6>
          <select class="input-field form-control" aria-label="Default select example" formControlName="industryRole"
            placeholder="Select your role" (change)="onItemSelect($event, 'industryRole')">
            <option value="" disabled selected>Select your option</option>
            <option *ngFor="let role of roleLists" [value]="role" [disabled]="isItemSelected(role, 'industryRole')"
              (keydown)="handleKeydown($event, 'industryRole')">
              {{ role }}
            </option>
          </select>
        </div>
        <div class="selected-roles mt-2">
          <span *ngFor="
                        let role of config['industryRole']['selectArray']
                      " class="badge bg-primary me-2">
            {{ role }}
            <button type="button" class="btn-close btn-close-white ms-2" aria-label="Close"
              (click)="removeItem(role, 'industryRole')"></button>
          </span>
        </div>

        <div formGroupName="location">
          <label for="location" class="text-white">Location (City, Country)</label>
          <input type="text" class="input-field form-select form-control" placeholder="Type Your Location"
            [value]="locationDisplay" (input)="handleSearch($event)" />
          <div class="filter-item filter-drop" style="max-width: 509px;" *ngIf="locationSuggestions.length">
            <ul>
              <li class="dropdown-value text-white" *ngFor="let suggestion of locationSuggestions"
                (click)="selectSuggestion(suggestion)">
                {{ suggestion.display_name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-2">
          <div class="text-white">Gender</div>
          <select id="gender" formControlName="gender" class="input-field form-control" placeholder="select a gender">
            <option value="" disabled selected>Select your option</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div class="mb-2">
          <div class="text-white">Date Of Birth</div>
          <input matInput [matDatepicker]="picker3" (click)="picker3.open()" (keydown)="(false)" formControlName="dob"
            class="input-field form-control" placeholder="Date Of Birth" />
          <mat-datepicker #picker3 [startAt]="startAt"></mat-datepicker>

        </div>
        <div class="mb-2" *ngIf="data.userData.loginType === 'google'">
          <label for="" class="title-normal">Phone Number</label>
          <input class="input-field form-control" type="text" formControlName="phoneNumber"
            placeholder="Phone Number" />
          <small class="text-danger" *ngIf="onboardingForm.get('phoneNumber')?.errors?.['customError']">
            {{ onboardingForm.get('phoneNumber')?.errors?.['customError'] }}
          </small>
        </div>

      </div>
    </div>

    <mat-dialog-actions>
      <button type="submit" id="button2" class="btn btn-grad-ff m-1" (click)="onSubmit()"
        [disabled]="onboardingForm.invalid">Submit</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
</div>